<template>
  <div>
    <h4>
      {{ $t("time.next.title") }} <help>{{ $t("time.next.info") }}</help>
    </h4>
    <inputSelect
      :key="getDefaultDuration()"
      :settings="{
        value: getDefaultDuration(),
        default: getDefaultDuration(),
        values: getValues()
      }"
      :callback="setSave"
    />
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      data: false,
      values: this.getValues()
    };
  },
  computed: {},
  mounted() {
    this.data = this.getDefaultDuration();
  },
  methods: {
    getValues() {
      var val = {
        "15": this.$t("time.next.option")[1],
        "30": this.$t("time.next.option")[2],
        "60": this.$t("time.next.option")[3]
      };

      if (
        typeof this.$store.state.business.unsaved.include.weekdays[
          this.$store.state.business.weekday
        ] !== "undefined" &&
        this.$store.state.business.unsaved.include.weekdays[
          this.$store.state.business.weekday
        ]
      ) {
        var test = this.$store.state.business.unsaved.include.weekdays[
          this.$store.state.business.weekday
        ][2];

        val[test] = this.$t("time.next.option")[0];
      }

      return val;
    },
    setSave(ret) {
      this.$store.commit("tableau/setUpdate");
      this.data = ret;
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      if (typeof data.next_appointments === "undefined") {
        data.next_appointments = {};
      }
      data.next_appointments[this.$store.state.business.weekday] = this.data;

      data.exclude.weekdays[this.$store.state.business.weekday] = [];

      if (this.$store.state.tableau.view === "week") {
        Object.keys(data.next_appointments).forEach(
          function(k) {
            data.next_appointments[k] = this.data;
            data.exclude.weekdays[k] = [];
          }.bind(this)
        );
      }
      this.$store.commit("business/prefetch", data);
    },
    getDefaultDuration() {
      if (
        typeof this.$store.state.business.unsaved.next_appointments[
          this.$store.state.business.weekday
        ] !== "undefined"
      ) {
        return this.$store.state.business.unsaved.next_appointments[
          this.$store.state.business.weekday
        ];
      }
      return this.$store.state.business.unsaved.include.weekdays[
        this.$store.state.business.weekday
      ][2];
    }
  }
};
</script>
<style lang="sass" scoped></style>
